<template>
    <v-card elevation="0" outlined v-if="taskItem && taskItem.ersRequest">
      <v-card-subtitle>
        <v-row dense>
          <v-col cols="auto"><v-chip color="primary" small><b>{{vs.renderErsIdToString(taskItem.ersId)}}</b></v-chip></v-col>
          <v-col cols="auto"><v-chip color="primary" outlined small><b>{{taskItem.ersId}}</b></v-chip></v-col>
          <v-col cols="auto" class="ml-3" v-if="taskItem.isConfirmed">
            <v-chip label small><b>승인자</b></v-chip> <v-chip color="warning" outlined label small><b>{{taskItem.confirmId}}</b></v-chip> <v-chip color="dark" outlined label small>{{vs.toDate(taskItem.confirmedAt)}}</v-chip>
          </v-col>
          <v-spacer />
          <v-col><v-select dense :items="templateOptions" v-model="message" item-value="text" item-text="text" placeholder="(메시지 전송 선택)" /></v-col>
          <v-col cols="auto"><v-btn small width="80" color="primary" @click.stop="sendMessage">전송</v-btn></v-col>
          <v-col cols="auto"><v-btn small width="100" color="info" @click.stop="approve" v-if="!taskItem.isConfirmed">승인</v-btn></v-col>
          <v-col cols="auto"><v-btn small width="100" color="error" @click.stop="cancelApprove" v-if="taskItem.isConfirmed">승인취소</v-btn></v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-card outlined flat>
              <v-card-title>접수 내용</v-card-title>
              <v-card-text>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.toDateYmd(taskItem.ersDts)" label="접수일시" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.renderVehicleCls(taskItem.ersRequest.vehicleCls)" label="접수차량 유형" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.ersRequest.vehicleKind" label="차종" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.ersRequest.plateNo" label="차량번호" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.ersRequest.addrBase" label="위치" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.renderRequestCode(taskItem.ersRequest.reqCode) + '/' + taskItem.ersRequest.reqMessage" label="요청내용" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.ersRequest.comment" label="전달사항" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.renderPayCode(taskItem.ersRequest.payCode)" label="유무상" /></v-col></v-row>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col class="ml-1">
            <v-card outlined flat>
              <v-card-title>출동 내용</v-card-title>
              <v-card-text>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.agencyName" label="접수가맹점" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.agentName" label="출동기사" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.toDate(taskItem.startedAt)" label="출동시간" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.toDate(taskItem.finishedAt)" label="완료시간" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.dispatchMin" label="출동소요시간(분)" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.ersRequest.addrBase" label="도착지" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly v-model="taskItem.dispatchKm" label="거리(Km)" /></v-col></v-row>
                <v-row dense><v-col><v-text-field dense readonly :value="vs.renderTaskStatus(taskItem.taskStatus)" label="결과" /></v-col></v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="ml-1">
            <v-card outlined flat>
              <v-card-title>조치 내용</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col><v-text-field dense readonly :value="vs.toDate(taskItem.createdAt)" label="조치일시" /></v-col>
                  <v-col cols="2"><v-text-field dense readonly v-model="taskItem.proofCount" label="사진" /></v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field dense readonly :value="vs.renderVehicleCls(taskItem.vehicleCls)" label="조치대상 차량 유형" :color="taskItem.vehicleCls!==taskItem.ersRequest.vehicleCls?'red':''" />
                  </v-col>
                  <v-col cols="2">
                    <v-chip x-small v-show="taskItem.vehicleCls!==taskItem.ersRequest.vehicleCls" color="warning">접수상이</v-chip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="4"><v-text-field dense readonly v-model="taskItem.taskName" label="조치내용" /></v-col>
                  <v-col><v-text-field dense readonly v-model="taskItem.taskDetail" label="조치상세" /></v-col></v-row>
                <v-row dense>
                  <v-col cols="2"><v-text-field dense readonly v-model="taskItem.towKm" label="견인거리" /></v-col>
                  <v-col><v-text-field dense readonly v-model="taskItem.towAddr" label="견인지" /></v-col>
                </v-row>
                <v-row dense>
                  <v-col class="text-right">
                    <v-chip class="mr-1" small :color="taskItem.isTow?'red':'gray'"><b class="text-gray-300">견인</b></v-chip>
                    <v-chip class="mr-1" small :color="taskItem.isNight?'red':'gray'"><b class="text-gray-300">야간</b></v-chip>
                    <v-chip class="mr-1" small :color="taskItem.isHighway?'red':'gray'"><b class="text-gray-300">고속</b></v-chip>
                    <v-chip class="mr-1" small :color="taskItem.awayLevel==='30'?'red':'gray'"><b class="text-gray-300">격오</b></v-chip>
                    <v-chip class="mr-1" small :color="taskItem.awayLevel==='20'?'red':'gray'"><b class="text-gray-300">준격오</b></v-chip>
                  </v-col>
                </v-row>
                <!--<v-row v-for="ext in taskItem.extCosts" dense>
                  <v-col><v-text-field dense readonly v-model="ext.codeName" label="할증항목" /></v-col>
                  <v-col><v-text-field dense readonly v-model="ext.amt" label="할증금액" /></v-col>
                </v-row>-->
                <v-row dense>
                  <v-col>
                      <v-simple-table class="mt-0" dense>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">
                              정산 항목
                            </th>
                            <th class="text-right">
                              금액
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                            v-for="item in taskItem.outcomes"
                            :key="item._id"
                          >
                            <td>{{ item.name }}</td>
                            <td class="text-right">{{ vs.comma(item.amt)}}</td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col><v-text-field dense v-model="taskItem.memo" label="전달사항" /></v-col>
                  <v-col cols="auto"><v-btn small color="info" :disabled="!taskItem.memo || !taskItem._id" @click.stop="saveMemo">등록</v-btn></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
import notify from '@/utils/notify'
import moment from 'moment'

export default {
  name: 'DispatchDetail',
  props: {
    taskItem: { type: Object, default: () => {} },
    templateOptions: { type: Array, default: () => [] }
  },
  metaInfo: {
    title: '상세내용'
  },
  data() {
    return {
      vs,
      message: null,
    }
  },
  methods: {
    sendMessage() {
      if (this.message) {
        api.sendMessageKakao(this.taskItem.agencyCode, this.message, [this.taskItem.agentId], this.taskItem.ersId).then(() => {
          notify.showMessage('전송되었습니다.')
        })
      }
    },
    approve() {
      if (this.taskItem._id) {
        api.putTaskConfirm(this.taskItem._id).then(() => {
          this.$emit('approved', this.taskItem._id)
          notify.showMessage('승인 되었습니다.')
        })
      }
    },
    cancelApprove() {
      if (this.taskItem._id) {
        api.putTaskConfirmCancel(this.taskItem._id).then(() => {
          this.$emit('approved', this.taskItem._id)
          notify.showMessage('승인 취소 되었습니다.')
        })
      }
    },
    saveMemo() {
      if (this.taskItem._id && this.taskItem.memo ) {
        api.putTaskMemo(this.taskItem._id, this.taskItem.memo).then(() => {
          notify.showMessage('등록 되었습니다.')
        })
      }
    }
  }
}
</script>
